import React, { createContext, useMemo, useCallback } from "react";
import {
  Process,
  TaskList,
  getFilterOptionsCount,
  getProcess,
  getProcessTasks,
  getTask as getTaskFromApi,
  updateTaskFieldValue as updateTaskFieldValueApi,
  updateProcess,
  updateTask as patchTask,
  createTask as createTaskAction,
  createField,
  updateField,
  deleteField,
  createChoice,
  updateChoice,
  deleteChoice,
  linkField,
  updateFields,
  createView,
  updateViews,
  updateView,
  deleteView,
} from "@/api/Process";
import { updateOrgFieldValue } from "@/api/Organization";

import { useAuth } from "@/hooks/useAuth";

import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import {
  depageData,
  getNextPageParam,
  mapSorting,
} from "@/components/InfiniteTable";

import { useParams } from "react-router-dom";

import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
} from "@tanstack/react-table";
import dayjs from "dayjs";
import omit from "lodash/omit";
import { updateTask as updateTaskAction } from "./actions";
import { filterBodyToArray } from "../ProcessView/ViewUtils";

type ProcessViewParams = { processId: string; "*": string };

export const SortTypeMap = {
  text: "alpha",
  date: "date",
  target_date: "date",
  number: "numeric",
  dollar: "numeric",
  company: "alpha",
  user: "alpha",
  user_multi: "alpha",
  select: "default",
  select_multi: "default",
  checkbox: "boolean",
  note: "alpha",
  latest_note: "alpha",
  person: "alpha",
  person_multi: "alpha",
};

export const FilterTypeMap = {
  text: "text",
  date: "date",
  target_date: "date",
  number: "number",
  dollar: "number",
  company: "company",
  user: "user",
  user_multi: "user_multi",
  select: "select",
  select_multi: "select",
  checkbox: "boolean",
  person: "person",
  person_multi: "person_multi",
  latest_note: "note",
  latest_org_note: "note",
  most_recent_comment: "comment",
};

export const fieldNameMap = {
  select: "choiceId",
  select_multi: "choiceId",
  company: "company",
  user: "user",
  user_multi: "user",
  person: "person",
  person_multi: "person",
  number: "number",
  dollar: "number",
  text: "value",
  text_multi: "value",
  checkbox: "value",
  date: "number",
  target_date: "number",
  ryg: "value",
  latest_note: "note",
  latest_org_note: "note",
  most_recent_comment: "comment",
};

export const ProcessDataContext = createContext<{
  id: string;
  name: string;
  entityName: string;
  fields: Process["fields"];
  fieldFilters: Process["fieldFilters"];
  settings: Process["settings"];
  tasks: TaskList["tasks"];
  isLoadingTasks: boolean;
  currentTaskId: string;
  groupedTasks: {
    group: string;
    tasks: TaskList["tasks"];
  }[];
  sorting: SortingState;
  columnFilters: ColumnFiltersState;
  columnVisibility: VisibilityState;
}>({
  id: "",
  name: "",
  entityName: "",
  fields: [],
  fieldFilters: [],
  settings: [],
  tasks: [],
  isLoadingTasks: true,
  currentTaskId: "",
  groupedTasks: [],
  sorting: [],
  columnFilters: [],
  columnVisibility: {},
});

export const ProcessActionsContext = createContext<{
  addTask:() => void;
  updateTask: () => void;
  archiveTask: () => void;
  fetchNextPage: () => void;
  setSorting: () => void;
  setColumnFilters: () => void;
  setColumnVisibility: () => void;
  setColumnOrder: () => void;
    }>({
      addTask: () => {},
      updateTask: () => {},
      archiveTask: () => {},
      fetchNextPage: () => {},
      setSorting: () => {},
      setColumnFilters: () => {},
      setColumnVisibility: () => {},
      setColumnOrder: () => {},
    });

export function ProcessProvider(props: {
  processId: string;
  viewId: string;
  mode: "grouped" | "ungrouped";
  activeGroup: string;
  query: string;
  archived?: boolean;
  children: React.ReactNode;
  sorting?: SortingState;
  columnFilters?: ColumnFiltersState;
  columnVisibility?: VisibilityState;
  columnOrder?: string[];
  onColumnFiltersChange?: (newFilter: ColumnFiltersState) => void;
  onSortingChange?: (newSort: SortingState) => void;
  onColumnVisibilityChange?: (newVisibility: VisibilityState) => void;
  onColumnOrderChange?: (newOrder: string[]) => void;
}) {
  const {
    processId,
    mode,
    activeGroup,
    query,
    sorting = [],
    onSortingChange = () => {},
    // columnFilters = [],
    onColumnFiltersChange = () => {},
    columnVisibility = {},
    onColumnVisibilityChange = () => {},
    columnOrder = [],
    onColumnOrderChange = () => {},
    children,
    archived = false,
    viewId,
  } = props;

  // deconstruct url
  const { "*": ticketId } = useParams<ProcessViewParams>();
  const idFragments = ticketId?.split("-");
  const currentTaskId = idFragments?.[idFragments.length - 1];

  // query client
  const queryClient = useQueryClient();

  // get process data
  const {
    data: processData = {
      name: "",
      fields: [],
      fieldFilters: [],
      organizationFields: [],
      settings: [],
      lifecycleStages: [],
    },
    isLoading: isProcessLoading,
    refetch: refetchProcess,
  } = useQuery(["process", processId], async () => getProcess(processId));
  const combinedFields = useMemo(() => ([
    ...processData.fields || [],
    ...processData.organizationFields || [],
  ].toSorted((a, b) => a.sort - b.sort)), [processData]);

  const sortedViews = useMemo(() => (
    processData?.views?.toSorted((a, b) => a.sort - b.sort)
  ), [processData]);

  const activeView = useMemo(
    () => (
      sortedViews?.find((v) => v.id === viewId)
    ),
    [sortedViews, viewId],
  );

  const columnFilters = useMemo(() => {
    // if filters are an array, return them
    const { columnFilters: filters } = props;
    if (Array.isArray(filters)) {
      return filters;
    }
    // if an object, convert to array
    if (filters) {
      return filterBodyToArray(filters, combinedFields);
    }

    return filters;
  }, [props, combinedFields]);

  const handleFilterChange = useCallback((newFilter) => {
    if (onColumnFiltersChange) {
      // hide conversion til we need to handle more complex filters in ui
      if (typeof newFilter === "function" || Array.isArray(newFilter)) {
        onColumnFiltersChange(newFilter);
      } else {
        // this handles saved views
        onColumnFiltersChange(
          filterBodyToArray(newFilter, combinedFields),
        );
      }
    }
  }, [onColumnFiltersChange, combinedFields]);

  const handleSortChange = useCallback(
    (newSort) => {
      if (onSortingChange) {
        onSortingChange(newSort);
      }
    },
    [onSortingChange],
  );

  const handleVisibilityChange = useCallback(
    (newVisibility) => {
      if (onColumnVisibilityChange) {
        onColumnVisibilityChange(newVisibility);
      }
    },
    [onColumnVisibilityChange],
  );

  const handleOrderChange = useCallback(
    (newOrder) => {
      if (onColumnOrderChange) {
        onColumnOrderChange(newOrder);
      }
    },
    [onColumnOrderChange],
  );

  const activeFieldFilters = useMemo(() => {
    // get the group by field
    const groupBy = activeGroup;

    // find field filters for it
    return processData?.fieldFilters?.filter(
      ({ subjectId }) => subjectId === groupBy,
    );
  }, [processData, activeGroup]);

  // all task data
  const LIMIT = 40;
  const getFilters = useMemo(() => {
    const filters = mode === "grouped" || archived
      ? {}
      : columnFilters?.reduce((acc, columnFilter) => {
        // get field & choices
        const [, , fieldId] = columnFilter.id.split(".");

        const field = combinedFields.find((f) => f.id === fieldId);
        const type = field?.type;

        if (type === "text") {
          return {
            ...acc,
            ilike: {
              ...(acc.ilike || {}),
              [columnFilter.id]: columnFilter.value,
            },
          };
        }

        if (
          type === "select"
              || type === "select_multi"
              || type === "ryg"
              || type === "user"
              || type === "user_multi"
              || type === "person"
              || type === "person_multi"
              || type === "company"
        ) {
          return {
            ...acc,
            eq: {
              ...(acc.eq || {}),
              [columnFilter.id]: columnFilter.value.map(
                ({ value: v }) => v,
              ),
            },
          };
        }

        if (type === "checkbox") {
          return {
            ...acc,
            eq: {
              ...(acc.eq || {}),
              [columnFilter.id]: [columnFilter.value ? "Yes" : "No"],
            },
          };
        }

        const rangeTypes = ["date", "target_date", "number", "dollar"];
        if (rangeTypes.includes(type) || columnFilter.id === "dateAdded") {
          let { min, max } = columnFilter.value;
          if (min === null && max === null) {
            return acc;
          }

          if (
            type === "date"
                || type === "target_date"
                || columnFilter.id === "dateAdded"
          ) {
            min = min ? dayjs(min).valueOf() : null;
            max = max ? dayjs(max).valueOf() : null;
          }
          return {
            ...acc,
            gte: {
              ...(acc.gte || {}),
              ...(min !== null ? { [columnFilter.id]: min } : {}),
            },
            lte: {
              ...(acc.lte || {}),
              ...(max !== null ? { [columnFilter.id]: max } : {}),
            },
          };
        }

        return {
          ...acc,
          eq: {
            ...(acc.eq || {}),
            [columnFilter.id]: columnFilter.value,
          },
        };
      }, {});
    const primaryCompanyField = combinedFields.find(
      (f) => f.type === "company" && f.isPrimary,
    );
    const nameField = combinedFields.find(
      (f) => f.type === "text" && f.name === "Name",
    );
    const descriptionField = combinedFields.find(
      (f) => f.type === "text_multi" && f.name === "Description",
    );

    return {
      AND: {
        ...filters,
        // ...(defaultFieldFilters || {}),
      },
      OR: {
        ...(query?.length > 2
          ? {
            // task name
            ilike: {
              ...(nameField
                ? { [`tasks.fieldValues.${nameField.id}.text`]: query }
                : {}),
              ...(descriptionField
                ? {
                  [`tasks.fieldValues.${descriptionField.id}.text`]: query,
                }
                : {}),
              ...(primaryCompanyField
                ? {
                  [`tasks.fieldValues.${primaryCompanyField.id}.company`]:
                        query,
                }
                : {}),
            },
          }
          : {}),
      },
    };
  }, [columnFilters, processData, query, mode, archived]);
  // all task data

  const pagedQueryKey = useMemo(
    () => [
      "processTasks",
      processId,
      query?.length > 2 ? query : undefined,
      sorting,
      columnFilters,
      mode,
      archived,
      processData?.fields,
      activeFieldFilters,
      activeView,
    ],
    [
      processId,
      query,
      sorting,
      columnFilters,
      mode,
      archived,
      processData?.fields,
      activeFieldFilters,
      activeView,
    ],
  );
  const {
    data: pagedTasks,
    isLoading: isLoadingTasks,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage: fetchNextPageTasks,
    refetch,
  } = useInfiniteQuery({
    queryKey: pagedQueryKey,
    queryFn: ({ pageParam = null }) => {
      const filterBody = getFilters;
      return getProcessTasks(
        [processId],
        mode === "grouped" ? 500 : LIMIT,
        pageParam,
        mode === "grouped" || archived ? [] : mapSorting(sorting),
        filterBody,
        archived && { archived },
      );
    },
    getNextPageParam,
    enabled: processData?.fields?.length > 0,
    refetchInterval: false,
  });

  const tasks = useMemo(() => depageData(pagedTasks), [pagedTasks]);

  // memoize tasks
  const data = useMemo<{
    tasks: TaskList["tasks"];
    groupedTasks: {
      group: string;
      tasks: TaskList["tasks"];
    }[];
  }>(() => ({
    id: processId,
    name: processData?.name || "",
    entityName: processData
      .settings
      ?.find(({ key }) => key === "entity_name")?.value
      || "Task",
    replyEntity: processData
      .settings
      ?.find(({ key }) => key === "reply_entity")?.value
      || "comments",
    enableSurveyLinking: processData
      .settings
      ?.find(({ key }) => key === "enable_survey_linking")?.value === "true"
      || false,
    fields: combinedFields,
    fieldFilters: processData.fieldFilters || [],
    views: sortedViews || [],
    settings: processData.settings || [],
    lifecycleId: processData.lifecycleProcessFieldId || "",
    lifecycleStages: processData.lifecycleStages || [],
    currentTaskId: currentTaskId || "",
    tasks: tasks || [],
    isLoadingTasks,
    isProcessLoading,
    groupedTasks: [],
    isFetching: isFetching || isFetchingNextPage,
    sorting,
    columnFilters,
    columnVisibility,
    columnOrder,
  }), [
    processId,
    processData,
    tasks,
    isLoadingTasks,
    currentTaskId,
    isFetching,
    isFetchingNextPage,
    sorting,
    columnFilters,
    columnVisibility,
    columnOrder,
    isProcessLoading,
    combinedFields,
    sortedViews,
  ]);

  // define actions
  const createTask = useCallback(
    (pId, task) => createTaskAction(pId, task),
    [],
  );
  const updateTask = useCallback(
    (
      updatedTaskId: string,
      fieldId: string,
      newValue: string,
      sortOrder: number,
    ) => {
      const taskToUpdate = tasks.find(
        (t) => t.id.toString() === updatedTaskId.toString(),
      );
      const field = combinedFields.find((f) => f.id === fieldId);

      if (!field || !taskToUpdate) {
        return;
      }

      const task = updateTaskAction(
        processId,
        taskToUpdate,
        field,
        newValue,
        sortOrder,
      );

      // update cache
      queryClient.setQueryData(
        pagedQueryKey,
        ({ pages, ...rest }) => ({
          ...rest,
          pages: pages.map((page) => ({
            ...page,
            data: page.data.map((t) => {
              if (t.id.toString() === updatedTaskId.toString()) {
                return task;
              }
              return t;
            }),
          })),
        }),
      );
    },
    [processData, tasks, queryClient, processId, pagedQueryKey],
  );

  const publishTask = useCallback(
    (taskId) => {
      // get task
      patchTask(processId, taskId.toString(), {
        published: true,
      });
    },
    [processId],
  );

  const archiveTask = useCallback(() => {}, []);
  // const fetchTask = useCallback(() => {}, []);
  const fetchNextPage = useCallback(() => {
    // fetch next page based on current filter / sort OR group
    if (!isFetching && !isFetchingNextPage && hasNextPage) {
      fetchNextPageTasks();
    }
  }, [isFetching, isFetchingNextPage, hasNextPage, fetchNextPageTasks]);
  // const fetchNextPageByGroup = useCallback(() => {

  // }, []);
  const updateProcessName = useCallback((newName) => {
    // update cache
    queryClient.setQueryData(
      ["process", processId],
      (oldData: TaskList) => ({
        ...oldData,
        name: newName,
      }),
    );

    // update server
    updateProcess({ id: processId, name: newName });
  }, [queryClient, processId]);

  const getTask = useCallback(
    async (pId: string, taskId: string, refresh = false) => {
      if (taskId === undefined) {
        throw new Error("Task Id is undefined");
      }
      let task = tasks.find((t) => t.id.toString() === taskId);
      if (!task || refresh) {
        task = await getTaskFromApi(pId, taskId);
        // add to cache
        queryClient.setQueryData(
          pagedQueryKey,
          (pagedQueryResult) => {
            if (!pagedQueryResult) {
              return {
                pages: [
                  {
                    data: [task],
                    nextPage: null,
                  },
                ],
                markers: {},
              };
            }

            const { pages, ...rest } = pagedQueryResult;
            return {
              ...rest,
              pages: pages?.map((page) => ({
                ...page,
                data: page.data.map((t) => {
                  if (t.id.toString() === taskId) {
                    return task;
                  }
                  return t;
                }),
              })),
            };
          },
        );
      }
      return task;
    },
    [queryClient, tasks, pagedQueryKey],
  );

  const toTypeFieldValue = (fieldType, fieldValue) => {
    if (fieldValue === null) {
      return null;
    }
    if (fieldType === "user_multi") {
      return fieldValue.map(({ id }) => ({ userId: id }));
    }
    if (fieldType === "person_multi") {
      return fieldValue.map(({ id }) => ({ personId: id }));
    }
    if (fieldType === "select_multi") {
      return fieldValue.map((choiceId) => ({ choiceId }));
    }
    return [
      {
        ...(fieldType === "company" && { valorId: fieldValue.valorId }),
        ...(fieldType === "user" && { userId: fieldValue.id }),
        ...(fieldType === "person" && { personId: fieldValue.id }),
        ...(fieldType === "select" && { choiceId: fieldValue }),
        ...([
          "ryg",
          "text",
          "text_multi",
        ].includes(fieldType) && { value: fieldValue }),
        ...([
          "number",
          "dollar",
          "date",
          "target_date",
        ]).includes(fieldType) && { numericValue: fieldValue === "" ? null : Number(fieldValue) },
        ...(fieldType === "checkbox" && {
          value: fieldValue ? "Yes" : "No",
        }),
      },
    ];
  };
  const updateTaskFieldValue = useCallback(
    async (
      pId: string,
      taskId: string, // TODO update to default.
      fieldType: string,
      fieldId: string,
      settingId: string,
      value: any,
      isOrganizationField?: boolean,
      primaryValorId?: string,
    ) => {
      const apiValue = toTypeFieldValue(fieldType, value);
      if (isOrganizationField && primaryValorId) {
        await updateOrgFieldValue(primaryValorId, settingId, apiValue);
      } else if (!isOrganizationField) {
        await updateTaskFieldValueApi(pId, taskId, fieldId, apiValue);
      } else {
        console.error(
          "Invalid field update, org field missing primary valor id.",
        );
      }
    },
    [queryClient, pagedQueryKey, processId],
  );

  const { user: currentUser } = useAuth();
  // updates cache, comment form makes call to api
  const addComment = useCallback(
    async (pId: string, taskId: string, comment: any) => {
    // update cache
      queryClient.setQueryData(
        pagedQueryKey,
        ({ pages, ...rest }) => ({
          ...rest,
          pages: pages.map((page) => ({
            ...page,
            data: page.data.map((t) => {
              if (t.id.toString() === taskId) {
                let newComments = [
                  ...(t.comments || []),
                  {
                    ...comment,
                    pending: true,
                    id: crypto.randomUUID(),
                    user: currentUser,
                    createdAt: dayjs.utc().toISOString(),
                  },
                ];
                if (comment.id) {
                  newComments = t.comments.map((c) => (c.id === comment.id
                    ? {
                      ...comment,
                      updatedAt: dayjs.utc().toISOString(),
                    }
                    : c));
                }
                return {
                  ...t,
                  comments: newComments,
                };
              }
              return t;
            }),
          })),
        }),
      );
    },
    [queryClient, pagedQueryKey, currentUser],
  );

  const getFilterCounts = useCallback(
    async (processFieldId) => {
      const filters = getFilters;
      const modifiedFilters = Object.keys(filters).reduce((acc, boolType) => {
        const ops = Object.keys(filters[boolType]).reduce((accOp, op) => {
          const oppFilters = Object.keys(filters[boolType][op]).reduce(
            (accFilter, field) => {
              if (field.indexOf(processFieldId) === -1) {
                return {
                  ...accFilter,
                  [field]: filters[boolType][op][field],
                };
              }
              return accFilter;
            },
            {},
          );
          if (Object.keys(filters).length > 0) {
            return {
              ...accOp,
              [op]: oppFilters,
            };
          }
          return accOp;
        }, {});
        // if (Object.keys(ops).length > 0) {
        acc[boolType] = ops;
        // }/
        return acc;
      }, {});
      return getFilterOptionsCount(processId, processFieldId, modifiedFilters);
    },
    [processId, getFilters],
  );

  // createField,
  const createFieldAction = useCallback(async (pId, fieldBody) => {
    const result = await createField(pId, fieldBody);
    queryClient.setQueryData(
      ["process", pId],
      (oldData) => ({
        ...oldData,
        fields: [...oldData.fields, result],
      }),
    );
    return result;
  }, [queryClient]);
  // updateField,
  const updateFieldAction = useCallback(async (pId, fieldBody) => {
    updateField(pId, fieldBody);
    queryClient.setQueryData(
      ["process", pId],
      (oldData) => ({
        ...oldData,
        fields: oldData.fields.map((f) => {
          if (f.id === fieldBody.id) {
            return {
              ...f,
              ...fieldBody,
            };
          }
          return f;
        }),
      }),
    );
  }, []);
  // deleteField,
  const deleteFieldAction = useCallback((pId, fieldId) => {
    deleteField(pId, fieldId);
    queryClient.setQueryData(
      ["process", pId],
      (oldData) => ({
        ...oldData,
        fields: oldData.fields.filter((f) => f.id !== fieldId),
        organizationFields: oldData.organizationFields.filter((f) => f.id !== fieldId),
      }),
    );
  }, [queryClient]);
  // createChoice,
  const createChoiceAction = useCallback(async (pId, fieldId, choiceBody) => {
    const result = await createChoice(
      pId,
      fieldId,
      choiceBody,
    );
    return result;
  }, [queryClient]);
  // updateChoice,
  const updateChoiceAction = useCallback(async (pId, fieldId, choiceBody) => {
    const result = await updateChoice(pId, fieldId, choiceBody);
    return result;
  }, []);
  // deleteChoice,
  const deleteChoiceAction = deleteChoice;
  // linkField,
  const linkFieldAction = useCallback((pId, organizationField) => {
    linkField(pId, organizationField.id).then((processField) => {
      queryClient.setQueryData(
        ["process", pId],
        (oldData) => ({
          ...oldData,
          organizationField: oldData.organizationFields.map((f) => {
            if (f.settingId === organizationField.id) {
              return processField;
            }
            return f;
          }),
        }),
      );
    });
    queryClient.setQueryData(
      ["process", pId],
      (oldData) => ({
        ...oldData,
        organizationFields: [
          ...oldData.organizationFields,
          {
            ...organizationField,
            name: organizationField.label,
            settingId: organizationField.id,
            isOrganizationField: true,
            sort: Math.max(...[
              ...oldData.fields,
              ...oldData.organizationFields,
            ].map((f) => f.sort)) + 1,
          },
        ],
      }),
    );
  }, [queryClient]);
  const updateFieldsAction = useCallback(async (pId, fields) => {
    updateFields(pId, fields);
    queryClient.setQueryData(
      ["process", pId],
      (oldData) => ({
        ...oldData,
        fields: oldData.fields.map((f) => {
          const field = fields.find((ff) => ff.id === f.id);
          return {
            ...f,
            ...field,
          };
        }),
        organizationFields: oldData.organizationFields.map((f) => {
          const field = fields.find((ff) => ff.id === f.id);
          return {
            ...f,
            ...field,
          };
        }),
      }),
    );
  }, [queryClient]);

  const createViewAction = useCallback(async (pId, viewBody) => {
    const newView = await createView(pId, viewBody);
    queryClient.setQueryData(
      ["process", pId],
      (oldData) => ({
        ...oldData,
        views: [
          ...oldData.views,
          newView,
        ],
      }),
    );
    return newView;
  }, [queryClient]);
  const updateViewAction = useCallback(async (pId, viewBody) => {
    updateView(pId, omit(viewBody, [
      "createdAt",
      "createdBy",
    ]));
    queryClient.setQueryData(
      ["process", pId],
      (oldData) => ({
        ...oldData,
        views: oldData.views.map((v) => {
          if (v.id === viewBody.id) {
            return viewBody;
          }
          return v;
        }),
      }),
    );
  }, [queryClient]);
  const updateViewsAction = useCallback(async (pId, views) => {
    // update server
    updateViews(pId, views);
    // update cache
    queryClient.setQueryData(
      ["process", pId],
      (oldData) => ({
        ...oldData,
        views: oldData.views.map((v) => {
          const view = views.find((vv) => vv.id === v.id);
          return {
            ...v,
            ...view,
          };
        }),
      }),
    );
  }, [queryClient]);
  const deleteViewAction = useCallback(async (pId, viewIdToDelete) => {
    // update server
    deleteView(pId, viewIdToDelete);
    // update cache
    queryClient.setQueryData(
      ["process", pId],
      (oldData) => ({
        ...oldData,
        views: oldData.views.filter((v) => v.id !== viewIdToDelete),
      }),
    );
  }, [queryClient]);

  const actions = useMemo<{
    createTask:(
    ) => void;
    updateTask: () => void;
    archiveTask: () => void;
    fetchNextPage: () => void;
    publishTask: (unpublishedTaskId: string) => void;
      }>(
      () => ({
        getFilterCounts,
        getTask,
        addComment,
        createTask,
        updateTask,
        publishTask,
        updateTaskFieldValue,
        archiveTask,
        updateProcessName,
        unarchiveTask: async (task) => {
        // eager remove from query cache
          queryClient.setQueryData(pagedQueryKey, (oldData) => ({
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              data: page.data.filter(({ id }) => id !== task.id),
            })),
          }));
          await patchTask(processId, task.id, { archived: false });
        },
        fetchNextPage,
        setSorting: handleSortChange,
        setColumnFilters: handleFilterChange,
        setColumnVisibility: handleVisibilityChange,
        setColumnOrder: handleOrderChange,
        refetch,
        createField: createFieldAction,
        updateField: updateFieldAction,
        deleteField: deleteFieldAction,
        createChoice: createChoiceAction,
        updateChoice: updateChoiceAction,
        deleteChoice: deleteChoiceAction,
        linkField: linkFieldAction,
        refetchProcess,
        updateFields: updateFieldsAction,
        createView: createViewAction,
        updateView: updateViewAction,
        updateViews: updateViewsAction,
        deleteView: deleteViewAction,
      }),
      [
        queryClient,
        createTask,
        updateTask,
        archiveTask,
        fetchNextPage,
        updateProcessName,
        handleSortChange,
        handleFilterChange,
        handleVisibilityChange,
        handleOrderChange,
        pagedQueryKey,
        processId,
        refetch,
        getTask,
        updateTaskFieldValue,
        addComment,
        getFilterCounts,
        createFieldAction,
        updateFieldAction,
        deleteFieldAction,
        createChoiceAction,
        updateChoiceAction,
        deleteChoiceAction,
        linkFieldAction,
        refetchProcess,
        updateFieldsAction,
        createViewAction,
        updateViewAction,
        updateViewsAction,
        deleteViewAction,
      ],
      );

  return (
    <ProcessDataContext.Provider value={data}>
      <ProcessActionsContext.Provider value={actions}>
        {children}
      </ProcessActionsContext.Provider>
    </ProcessDataContext.Provider>
  );
}

export function useProcessData() {
  const context = React.useContext(ProcessDataContext);
  if (!context) {
    throw new Error("useProcess must be used within a ProcessProvider");
  }
  return context;
}

export function useProcessActions() {
  const context = React.useContext(ProcessActionsContext);
  if (!context) {
    throw new Error("useProcessActions must be used within a ProcessProvider");
  }
  return context;
}
